// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  StyledHeader,
  ContentContainer,
  StyledPicture,
  ContentWrapper,
  breakpoint,
  StampWrapper,
} from './HeroWithTitle.styles';
import { greaterThanCondition } from '../../utils/mediaQuery';

const HeroWithTitle = ({
  children,
  pictureSmall,
  pictureLarge,
  renderStamp,
  backgroundColor,
}) => {
  const sources = [
    { ...pictureSmall, sizes: `92vw` },
    {
      ...pictureLarge,
      sizes: `(min-width: 1560px) 844px, ${(784 / 1440) * 100}vw`,
      media: `${greaterThanCondition(breakpoint)}`,
    },
  ];
  return (
    <StyledHeader>
      <ContentContainer backgroundColor={backgroundColor}>
        <ContentWrapper>{children}</ContentWrapper>

        {renderStamp && <StampWrapper>{renderStamp}</StampWrapper>}
      </ContentContainer>

      <StyledPicture fluid={sources} />
    </StyledHeader>
  );
};

HeroWithTitle.propTypes = {
  children: PropTypes.node.isRequired,
  pictureLarge: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
  }).isRequired,
  pictureSmall: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
  }).isRequired,
  renderStamp: PropTypes.node,
  backgroundColor: PropTypes.string,
};

HeroWithTitle.defaultProps = {
  renderStamp: undefined,
  backgroundColor: undefined,
};

export default HeroWithTitle;

export const query = graphql`
  fragment HeroWithTitleLargePictureData on File {
    childImageSharp {
      fluid(maxWidth: 844, maxHeight: 720, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }

  fragment HeroWithTitleSmallPictureData on File {
    childImageSharp {
      fluid(maxWidth: 740, maxHeight: 922, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
