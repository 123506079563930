// vendors
import styled from 'styled-components';
import { rem } from 'polished';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';

export const List = styled.ul`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: ${rem(12)} ${rem(80)};
  max-width: ${rem(1360)};

  margin: auto;
  padding: 0;

  list-style: none;

  ${greaterThan(breakpoints.fonts[0])} {
    grid-gap: ${rem(20)} ${rem(40)};
    grid-template-columns: repeat(auto-fill, minmax(${rem(401)}, 1fr));
  }

  ${greaterThan(breakpoints.fonts[1])} {
    grid-gap: ${rem(40)} ${rem(80)};
  }
`;

export const ListItem = styled.li``;
