// vendors
import React from 'react';
import PropTypes from 'prop-types';
import LinkCard from './LinkCard';
import { List, ListItem } from './LinkCardList.styles';
import colors from '../../styles/colors';

export const linkCardColorArray = [
  colors.robinEggBlue,
  colors.azure,
  colors.waterBlue,
  colors.twilightBlue,
  colors.black,
];

const LinkCardList = ({ nodes }) => {
  return (
    <List>
      {nodes.map(({ abbreviation, name, to }, index) => (
        <ListItem
          style={{
            color: linkCardColorArray[index % linkCardColorArray.length],
          }}
        >
          <LinkCard abbr={abbreviation} title={name} to={to} />
        </ListItem>
      ))}
    </List>
  );
};

LinkCardList.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string,
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default LinkCardList;
