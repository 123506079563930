// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';

import colors from '../styles/colors';
import LinkCardList from '../components/LinkCardList';
import responsiveStyle from '../utils/responsiveStyle';
import breakpoints from '../styles/breakpoints';
import HeroWithTitle from '../components/HeroWithTitle';
import breakpointsRange from '../utils/breakpointsRange';
import SEO from '../components/SEO';

const TitleWrapper = styled.div`
  display: flex;

  color: ${colors.white};

  h1 {
    flex-basis: 0;

    margin: 0;

    line-height: ${35 / 30};

    word-break: initial;

    ${breakpointsRange(
      [{ prop: 'fontSize', sizes: [30, 56] }],
      breakpoints.fonts
    )};
  }
`;

const LinkSection = styled.section`
  padding: 0;

  ${responsiveStyle('marginTop', [64, 168], breakpoints.spacings)};
  ${responsiveStyle('paddingTop', [64, 168], breakpoints.spacings)};
  ${responsiveStyle('paddingBottom', [64, 168], breakpoints.spacings)};

  background-color: ${colors.veryLightPink};
`;

const ConseilsPostoperatoiresPage = ({ data }) => {
  const {
    headerImageLarge: {
      childImageSharp: { fluid: headerImageLarge },
    },
    headerImageSmall: {
      childImageSharp: { fluid: headerImageSmall },
    },
    advices: { edges: advices = [] },
  } = data;

  const linkNodes = advices.map(({ node }) => ({
    name: node.name,
    to: `/conseils-postoperatoires/${node.slug.current}`,
  }));

  return (
    <Layout hasNoHeaderSpacer>
      <SEO
        title='Après mon opération'
        description='Vous avez subi une chirurgie dentaire à une clinique Maxillo Québec Lebourgneuf et Lévis, voici les conseils à suivre suite à votre opération.'
      />

      <article>
        <HeroWithTitle
          pictureSmall={headerImageSmall}
          pictureLarge={headerImageLarge}
          backgroundColor={colors.twilightBlue}
        >
          <TitleWrapper>
            <h1>Après&nbsp;mon opération</h1>
          </TitleWrapper>
        </HeroWithTitle>

        <LinkSection>
          <LinkCardList nodes={linkNodes} />
        </LinkSection>
      </article>
    </Layout>
  );
};

ConseilsPostoperatoiresPage.propTypes = {
  data: PropTypes.shape({
    headerImageLarge: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    headerImageSmall: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    advices: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.shape({
              current: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        })
      ),
    }),
  }).isRequired,
};

export default ConseilsPostoperatoiresPage;

export const query = graphql`
  query {
    headerImageLarge: file(name: { eq: "img-header-postoperation-2520x2160" }) {
      ...HeroWithTitleLargePictureData
    }
    headerImageSmall: file(name: { eq: "img-header-postoperation-2220x2766" }) {
      ...HeroWithTitleSmallPictureData
    }
    advices: allSanityAdvicePostOperation(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
        }
      }
    }
  }
`;
