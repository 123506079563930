import styled from 'styled-components';
import { rem, between } from 'polished';
import Img from 'gatsby-image';

import colors from '../../styles/colors';
import { greaterThan, lessThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import breakpointsRange from '../../utils/breakpointsRange';

export const breakpoint = breakpoints[2];

export const StyledHeader = styled.header`
  position: relative;

  display: flex;

  max-width: ${rem(1440)};
  margin: auto;

  ${breakpointsRange(
    [
      {
        prop: 'marginBottom',
        sizes: [128, 168],
      },
    ],
    breakpoints.spacings
  )}

  ${lessThan(breakpoints[4])} {
    flex-flow: column;
  }

  ${greaterThan(breakpoints[4])} {
    align-items: flex-end;
  }
`;

export const StyledPicture = styled(Img)`
  width: calc(100vw - ${rem(28)});
  margin-left: ${rem(28)};

  ${greaterThan(breakpoints[2])} {
    width: calc(100vw - ${rem(40)});
    margin-left: ${rem(40)};
  }

  ${greaterThan(breakpoints[4])} {
    width: ${(784 / 1440) * 100}vw;

    margin-left: 0;
  }

  ${greaterThan(1440)} {
    width: ${between('784px', '844px', '1440px', '1560px')};
    margin-right: ${between('0px', '-60px', '1440px', '1560px')};
  }

  ${greaterThan(1560)} {
    width: ${rem(844)};
    margin-right: -60px;
  }
`;

export const ContentContainer = styled.div`
  position: relative;

  z-index: 1;

  margin-top: -${rem(44)};
  margin-right: 28px;
  margin-left: 0;

  padding-top: ${56 + 44}px;
  padding-right: 28px;
  padding-bottom: 56px;
  padding-left: 28px;

  background-color: ${({ backgroundColor }) => backgroundColor || colors.azure};

  mix-blend-mode: multiply;

  ${lessThan(breakpoints[4])} {
    order: 1;
  }

  ${greaterThan(breakpoints[2])} {
    margin-right: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }

  ${greaterThan(breakpoints[4])} {
    flex: 1 1;
    margin-right: -40px;
    margin-bottom: 40px;
    margin-left: 40px;

    padding: 72px 40px;
  }

  ${greaterThan(breakpoints[6])} {
    margin-right: -80px;
    margin-bottom: 80px;

    padding: 128px 80px;
  }
`;

export const ContentWrapper = styled.div`
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StampWrapper = styled.div`
  position: absolute;

  top: calc(100% - 32px);
  right: ${28}px;

  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [120, 194],
      },
    ],
    breakpoints.fonts
  )}

  ${greaterThan(breakpoints[2])} {
    top: calc(100% - 48px);
    right: 40px;
  }

  ${greaterThan(breakpoints[4])} {
    top: calc(100% - 48px);
    right: ${40 + 72}px;
  }

  ${greaterThan(breakpoints[6])} {
    top: calc(100% - 48px);
    right: ${80 + 72}px;
  }
`;
