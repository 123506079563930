// vendors
import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  AbbrContainer,
  AbbrWrapper,
  TitleWrapper,
  ArrowWrapper,
} from './LinkCard.styles';
import IconArrow from '../../../images/IconArrow';

const LinkCard = ({ abbr, title, to }) => {
  return (
    <Container to={to}>
      {abbr && (
        <AbbrContainer role='presentation'>
          <AbbrWrapper>{abbr}</AbbrWrapper>
        </AbbrContainer>
      )}

      <TitleWrapper>{title}</TitleWrapper>

      <ArrowWrapper>
        <IconArrow />
      </ArrowWrapper>
    </Container>
  );
};

LinkCard.propTypes = {
  abbr: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

LinkCard.defaultProps = {
  abbr: undefined,
};

export default LinkCard;
