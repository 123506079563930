// vendors
import styled from 'styled-components';
import { Link } from 'gatsby';
import { em, rem } from 'polished';
import colors from '../../../styles/colors';
import { greaterThan } from '../../../utils/mediaQuery';
import breakpoints from '../../../styles/breakpoints';
import { speed, easing } from '../../../styles/animation';
import breakpointsRange from '../../../utils/breakpointsRange';

export const ArrowWrapper = styled.span`
  flex-shrink: 0;
  width: 12px;

  margin-left: ${em(24, 15)};

  transform: translateX(-8px);

  transition: transform ${speed.fast} ${easing.inQuint};

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const Container = styled(Link)`
  display: flex;
  align-items: center;

  ${breakpointsRange(
    [{ prop: 'minHeight', sizes: [80, 144] }],
    breakpoints.fonts
  )}

  padding: 12px 16px;

  color: currentColor;
  text-decoration: none;

  background-color: ${colors.white};

  ${greaterThan(breakpoints.fonts[0])} {
    padding: 20px 28px;

    font-size: ${rem(24)};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${rem(30)};
  }

  :hover,
  :focus {
    ${ArrowWrapper} {
      transform: translateX(0);

      transition: transform ${speed.fast} ${easing.outQuint};

      @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }
  }
`;

export const AbbrContainer = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${em(52, 20)};
  height: ${em(52, 20)};
  margin-right: ${em(24, 20)};

  font-weight: 600;
  font-size: ${em(20, 15)};

  background-color: currentColor;

  border-radius: 100%;

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${em(40, 30)};
  }
`;

export const AbbrWrapper = styled.span`
  color: ${colors.white};

  text-transform: uppercase;
`;

export const TitleWrapper = styled.span`
  flex-grow: 1;

  color: ${colors.black};
`;
